.CanopyStressHeader {
    margin-left: 30px;
    margin-right: 10px;

    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: inherit;
}

.headerLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.subHeadder {
    margin-left: 30px;
    font-size: 0.8rem;
    color: #666666e3;
}

.fieldDetailBody {
    margin-left: 0px;
    margin-right: 0px;
}

.stressHours {
    margin-left: 10px;
    font-weight: 300;
    font-size: 0.9rem;
}

.headerRight {
    margin-right: 30px;
}