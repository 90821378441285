.routerLink {
    /* display: block; */
    margin-top: 22px;
    color: #000;
    font: normal normal normal 14px/20px Poppins;
    text-decoration: none;
}

/* So the link doesn't change when it is visited */
.routerLink:visited {
    color: #000;
}

.routerLink:hover {
    text-decoration: underline;
    color: #0958FF;
}