.textActionList {
    margin: 20px 20px 43px 20px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;
    width: calc(100% - 40px);
}

.innerBox {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}