.Dashboard {
    margin-left: 24px;
    margin-top: 0px;
}

.Cards {
    margin-top: 30px;
    display: flex;
    justify-content: left;
    gap: 40px;
}

.Cards>div {
    margin-bottom: 20px;
}

.DashboardTop {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-right: 16px;
}

.Title {
    font: 600 17px/26px 'Poppins';
    letter-spacing: 0.11px;
}

.Date {
    font: normal 14px/20px Poppins;
    margin-top: 15px;
}

.ManageLink {
    display: block;
    margin-top: 24px;
    color: #0958FF;
    font: normal normal normal 14px/20px Poppins;
    text-decoration: none;
}

/* So the link doesn't change when it is visited */
.ManageLink:visited {
    color: #0958FF;
}

.ManageLink:hover {
    text-decoration: underline;
    color: #0958FF;
}

.EditButton {
    margin-top: 15px;
}

@media screen and (max-width: 1200px) {
    .Cards {
        width: 95vw;
    }
}

@media screen and (max-width: 800px) {
    .Cards {
        flex-wrap: wrap;
        gap: 20px;
    }
}


@media screen and (max-width: 500px) {
    .Dashboard {
        margin-left: 10px;
    }
    .Cards {
        justify-content: center;
    }
}

@media screen and (max-height: 500px) and (max-width:1200px) {
    .Cards {
        flex-wrap: nowrap;
        gap: 20px;
    }

    .Cards>div {
        width: 100%;
    }
}