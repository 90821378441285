.ql-toolbar {
    margin-top: 28px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: none;
  }

  .ql-container {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .ql-toolbar.ql-snow .ql-formats {

  }

  .ql-editor {
    font-size: 1.5rem;
    font-weight: normal;
  }