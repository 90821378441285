.fieldListComponentWrapper {
    width: 100%;
    height: calc(100vh - 250px);
    overflow-y: scroll;
}

/* DO NOT REMOVE, IT IS HERE FOR BINDING 2 DIFFERENT ELEMENTS TO SCROLL TOGETHER */
.scrollBind {}

@media only screen and (max-width: 800px) {
    .fieldListComponentWrapper {
        overflow: scroll;
    }

    .scrollBind {
        width: 100%;
        padding: 10px 0px;
        overflow-x: scroll;
    }
}