@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.banner {
    height: 40px;
    /* background-color: #22222228; */
    /* color: white; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    pointer-events: none;
    font: 400 14px/20px Poppins;
}

.uat{
    color: #3eb311;
    background-color: #26dd1528;
}

.dev{
    color: #e6e610;
    background-color: #f8e91628;
}