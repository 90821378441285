.Title {
    font: 500 18px/26px 'Poppins';
    letter-spacing: 0.11px;
    text-align: left;
    margin-right: 7px;
    margin-bottom: 15px;
}

.Container {
    padding: 15px;
    width: 400px;
}

.toolbartitle {
    font-size: large;
    font-weight: 500;
}

.toolbarcontent {
    margin-top: 5px;
    margin-bottom: 5px;
}