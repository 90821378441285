#GoannaCard {
    max-width: 500px;
    width: 500px;
    height: 350px;
    border-radius: 16px 16px 16px 16px;
    box-shadow: 0px 3px 6px #00000029;
    transition: box-shadow 0.3s;
}

#GoannaCard:hover {
    cursor: pointer;
    box-shadow: 0px 3px 18px #00000029;
}

.SideIconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    margin-right: 18px;
    margin-top: 25px;
    border-radius: 50%;
    border-color: #00000080;
    border-width: 1px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.SideIconButton:hover {
    cursor: pointer;
}

.Content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    row-gap: 60px;
}

.ContentTextContainer {
    margin-top: 35px;
    margin-left: 20px;
    display: table;
    width: 100%; 
}

.ContentTextContainer .ContentTable {
    width: 100%; 
    border-collapse: collapse; 
}

.ContentTable tr {
    display: table-row; 
    font: 400 14px/20px Poppins;
    margin-left: 12px;
}

.ContentTable tr th {
    padding-left: 15px; 
    text-align: right; 
}

.ContentTable tr td {
    padding-right: 15px;
    text-align: left;
}

.ContentText .ContentTextData {
    font: 400 14px/20px Poppins;
    margin-left: 12px;
}

.ContentText .ContentTextDate {
    color: #7E84A3;
    font: normal normal normal 12px/20px Poppins;
    margin-left: 12px;
    margin-top: 5px;
}

.ContentText .ContentTextNumber {
    font: 400 40px/60px Poppins;
    letter-spacing: 0.17px;
}

.Footer {
    margin-top: 27px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: start;
}

.Footer button {
    color: #F8B00C;
    border-color: #F8B00C;
    border-radius: 4px
}

.Footer button:hover {
    border-color: #F8B00C;
    box-shadow: 0px 1px 4px #00000029;
}

.Footer div {
    width: 369px;
    font: 400 italic 14px/20px Poppins;
    padding-left: 20px;
    color: #7E84A3;

}


/* tablet screen */
@media screen and (max-width: 1200px) and (min-height:500px) {
    #GoannaCard {
        width: calc(50vw - 80px);
    }

    .SideIconButton {
        width: 100px;
        height: 100px;
    }

    .Content img {
        width: 90px;
    }

    .ContentTextContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 30px;
    }
}

/* smaller tablet */
@media screen and (max-width: 800px) and (min-height:500px) {
    #GoannaCard {
        width: 500px;
    }

    .SideIconButton {
        width: 150px;
        height: 150px;
    }

    .Content img {
        width: 130px;
    }

    .ContentTextContainer {
        display: initial;
        flex-direction: initial;
        flex-wrap: initial;
        gap: initial;
    }
}

/* phone */
@media screen and (max-width: 620px) {
    #GoannaCard {
        width: calc(90vw - 80px);
    }

    .SideIconButton {
        width: 100px;
        height: 100px;
    }

    .Content img {
        width: 90px;
    }

    .ContentTextContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 30px;
    }
}

@media screen and (max-width: 500px) {
    #GoannaCard {
        width: 90vw;
    }

    .SideIconButton {
        width: 100px;
        height: 100px;
    }

    .Content img {
        width: 90px;
    }

    .ContentTextContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 30px;
    }
}

@media screen and (max-width: 350px) {
    #GoannaCard {
        width: 90vw;
    }

    .SideIconButton {
        width: 50px;
        height: 50px;
    }

    .Content img {
        width: 35px;
    }

    .ContentTextContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 30px;
    }
}

/* phone landscape */
@media screen and (max-width:1200px) and (max-height: 500px) {
    #GoannaCard {
        width: inherit;
    }

    .SideIconButton {
        width: 100px;
        height: 100px;
    }

    .Content img {
        width: 90px;
    }

    .ContentTextContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 30px;
    }
}

@media screen and (max-width:500px) and (max-height: 500px) {
    #GoannaCard {
        width: 90vw;
    }
}

@media  (max-width: 390px) {
    .Footer {
        margin-top: 0;
    }
}