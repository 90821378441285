.wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.grid {
    width: 100%;
    height: calc(100vh - 150px);
}

.pageHeader {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    align-items: stretch;
}

.Title {
    font: 500 24px 'Poppins';
    letter-spacing: 0.11px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.toggleButton {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.topLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}