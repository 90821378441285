.SoilTotalHeader {
    margin-left: 30px;
    margin-right: 10px;
    font-weight: 600;
    font-size: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardHearerLeft {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.fieldDetailBody {
    margin-left: 0px;
    margin-right: 0px;
}

.cardHeaderRight {
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.cardHeaderRight span {
    font-weight: 300;
    font-size: 0.9rem;
    margin-right: 5px;
}

.secondSpan {
    margin-left: 10px;
}

.checkbox {
    padding-left: 0px !important;
    margin: auto 0px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
