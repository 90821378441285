@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topbar {
  /* transition: margin-left .3s ease-out; */
  position: fixed;
  width: -webkit-fill-available;
  z-index: 999;
}

/* Position and style the close button (top right corner) */
.closebtn {
  position: absolute;
  top: 0;
  font-size: 27px;
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  z-index: -1;
}

.closebtn .Logo {
  color: #707070;
  margin-left: 13px;
  font: normal normal normal 20px/27px Segoe UI;
}

.closebtn .logoName {
  display: flex;
  align-items: center;
  height: 68px;
}

.sidenav a.closebtn {
  padding: 0;
  padding-left: 16px;
}

.GtIcon {
  transform-origin: center;
  margin-left: 45px;
  transition: all .3s ease-out;
}

.LogoAg {
  color: #3FCA55;
}

.LinkText {
  margin-left: 20px;
  white-space: nowrap;
  transition: opacity .3s ease-out;
  font: 500 14px/21px Poppins;
  letter-spacing: 0.1px;
  color: #171725;
}

/* https://www.w3schools.com/howto/howto_js_Navbar-sidenav.asp */
/* The side navigation menu */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-x: hidden;
  padding-top: 68px;
  transition: 0.3s ease-out;
  overflow: 'hidden';
  justify-content: space-between;
}

.sidenavTop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

/* The navigation menu links */
.sidenavTop a {
  display: inline-flex;
  padding: 8px 8px 8px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #171725;
  width: min-content;
  align-items: center;
  font-weight: bold;
}

.sidenav a svg {
  width: 24px;
}

.selected {
  border-left: #0958FF 10px;
  background: #F4F7FC 0% 0% no-repeat padding-box;
  border-radius: 0px 4px 4px 0px;
}

.sidenav a:not(:first-child).selected {
  border-color: #0958FF;

}

.selected .LinkText {
  color: #0958FF;
}

/* 
  https://webvista.co.in/tools/css_filter/
  Use this to calculate filter for Hex
  */
.selected svg {
  filter: brightness(0) saturate(100%) invert(27%) sepia(48%) saturate(6785%) hue-rotate(220deg) brightness(99%) contrast(110%);
}

.sidenav a:hover:not(:first-child) {
  background: #F4F7FC;
}

.sidenav a:not(:first-child) {
  border-left: 3px #FFF solid;
  height: 36px;
}

@media screen and (max-height: 450px) {
  .sidenav a {
    font-size: 18px;
  }
}

.userprofile:hover {
  cursor: pointer;
}

.userprofile {
  display: inline-flex;
  padding: 8px 8px 8px 16px;
  width: min-content;
  align-items: center;
}

