.text-field-width {
    width: 70%;
    min-width: 150px;
}

@media screen and (max-width: 500px) {
    .text-field-width {
        width: 150px;
    }
}

@media screen and (max-width: 340px) {
    .text-field-width {
        width: 100%;
    }
}