.button {
    line-height: 1;
    text-transform: uppercase;
    position: relative;
    border: .1rem solid;
    padding: 0.7em;
    transition: background-color .3s;
    border-radius: 50%;
    margin-left: 10px;
    margin-top: 5px;
}
/* .button:hover {
    color: #ffffff;
    background: #1085FD;
    border-color: #1085FD;
} */
/* .button:active {
    top: .08em;
} */

.svgIcon {
    position: relative;
    top: .125em;
    flex-shrink: 0;
    height: 1em;
    width: 1em;
    fill: #848aa7;
    stroke: #7e84a3;
    transition: fill .3s;
}
/* 
.button:active .svgIcon {
    fill: #ffffff;
    stroke: #ffffff;
} */

/* .button:hover .svgIcon {
    fill: #ffffff;
    stroke: #ffffff;
} */

/* .button:focus .svgIcon {
    fill: #ffffff;
    stroke: #ffffff;
} */

.buttonOff {
    border-color: #848aa7;
    color: '#848aa7';
    background: #ffffff;
}

.svgIconOff {
    fill: #848aa7;
    stroke: #7e84a3;
}

.buttonOn {
    color: #ffffff;
    background: #1085FD;
    border-color: #1085FD;
}

.svgIconOn {
    fill: #ffffff;
    stroke: #ffffff;
}

.popperWrapper {
    width: 500px;
}

.popperheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    padding-top: 20px;
    margin-bottom: 20px;
}

.selectorWrapper {
    width: 50%;
}

.textIcon {
    display: flex;
    align-items: center;
}

.popperSectionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.cancelButton {
    flex-basis: 45%;
}

.headerText {
    flex-basis: 34%;
}

.resetButton {
    flex-basis: 30%;
}

.submitButtom {
    display: flex;
    justify-content: center;
    margin: 5px;
    padding-bottom: 20px;
    margin-top: 30px;
}

.popperFieldStatus {
    margin: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}