/* .App {
    display: flex;
    flex-direction: column;
} */

.topbar {
    height: 40px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    z-index: 97;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.hamburger {
    width: 20px;
    height: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 10px 6px;
}

.hamburger:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.hamburger:active {
    text-decoration: none;
    text-transform: none;
    background-color: rgba(0, 0, 0, 0.2);
}

.line {
    width: 70%;
    height: 2px;
    background-color: #343638;
    align-self: center;
}

/* .line#center {
    width: 50%;
} */

.menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background-color: #F6F8F9;
    visibility: hidden;
    overflow: hidden;
    z-index: 99;
    transform: translateX(-100%);
    animation-duration: 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
    transition: 0.3s ease-out;
}

@keyframes slideIn {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}

.menu.open {
    visibility: visible;
    animation-name: slideIn;
    animation-fill-mode: forwards;
}

/**for item styling*/
.menu a {
    display: inline-flex;
    padding: 8px 8px 8px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #171725;
    align-items: center;
    font-weight: bold;
    width: 100% !important;
}

.menu a svg {
    width: 24px;
}

.menu a:hover:not(:first-child) {
    background: #e9eff9;
}

.menu a:not(:first-child) {
    border-left: 3px #F6F8F9 solid;
    height: 36px;
}

.content {
    flex-grow: 1;
    padding-top: 40px;
}

.closebtn {
    font-size: 27px;
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    z-index: -1;
    text-decoration: none;
    padding-left: 16px;
}

.closebtn .Logo {
    color: #707070;
    margin-left: 13px;
    font: normal normal normal 20px/27px Segoe UI;
}

.closebtn .logoName {
    display: flex;
    align-items: center;
    height: 68px;
}

.menu a.closebtn {
    padding: 0;
    padding-left: 16px;
}

.GtIcon {
    transform-origin: center;
    margin-left: 45px;
    transition: all .3s;
}

.LogoAg {
    color: #3FCA55;
    text-decoration: none;
}

.LinkText {
    margin-left: 20px;
    white-space: nowrap;
    transition: opacity .3s ease-out;
    font: 500 14px/21px Poppins;
    letter-spacing: 0.1px;
    color: #171725;
    text-decoration: none;
}

.selected {
    border-left: #0958FF 10px;
    background: #e9eff9 0% 0% no-repeat padding-box;
    border-radius: 0px 4px 4px 0px;
}

.selected .LinkText {
    color: #0958FF;
}


.selected svg {
    filter: brightness(0) saturate(100%) invert(27%) sepia(48%) saturate(6785%) hue-rotate(220deg) brightness(99%) contrast(110%);
}

.menu a:not(:first-child).selected {
    border-color: #0958FF;

}

.title {
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 500;
    text-align: start;
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width:500px) {
    .title {
        max-width: 150px;
    }
}