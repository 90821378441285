.searchBarWrapper {
    position: relative;
    width: 500px;
    display: flex;
    flex-direction: row;
    transition: width .3s;
}

.searchBarWrapper:focus-within {
    width: 400px;
}

.autocomplete {
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
}

.button {
    position: absolute;
    top: 2px;
    left: 400px;
}

@media screen and (max-width: 1300px) {
    .searchBarWrapper {
        width: 300px
    }

    .searchBarWrapper:focus-within {
        width: 200px;
    }
    .button {
        position: absolute;
        left: 200px;
    }
}

@media screen and (max-width: 680px) {
    .searchBarWrapper {
        width: 100%;
    }
}