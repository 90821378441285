.wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.grid {
    width: 100%;
    height: calc(100vh - 300px);
}

.RootzoneSoilMoistureProfileFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 500px) {
    .wrapper {
        padding-left: 0px;
        padding-right: 0px;
    }
}