.topTile {
    width: 9%;
    height: 120px;
    border-radius: 16px;
    opacity: 1;
    background-origin: padding-box;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.topTileWrapper {
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-left: 30px;
    padding-right: 50px;
    padding-bottom: 15px;
    font-family: 'poppins';
    font-size: 14px;
}

.rainDropBall {
    background-color: #1085FD;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
}

.totalIrrigation {
    display: inline-flex;
    flex-direction: row;
    align-items: baseline;
}

.date {
    font-weight: bold;
}

.topTileItem {
    margin-top: 10px;
    margin-bottom: 10px;
}

.days {
    font-weight: 500;
}

@media only screen and (max-width: 800px) {

    .topTileWrapper {
        width: 800px;
    }

}
@media only screen and (max-width: 680px) {

    .topTileWrapper {
        padding-left: 10px;
    }

}