.fieldListTopBarWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* width: 100%; */
    height: 45px;
    opacity: 1;
    padding-bottom: 1px;

}

.leftSide {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
}

.selectWrapper {
    width: 250px;
    padding-right: 20px;
}

.menuItem {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.menuItemArrow {
    align-self: center;
}

.fieldListTopBar {
    margin-left: 30px;
    margin-right: 50px;
    background: #D7E5FC 0% 0% no-repeat padding-box;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.fieldListTopBarLegend {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
    font-family: 'poppins';
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 10px;
}

.legendRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

.greenBall {
    background-color: #2D9C41;
    width: 10px;
    height: 10px;
    border: 1px;
    border-color: #707070;
    border-radius: 50%;
    margin-right: 4px;
}

.blueBall {
    background-color: #1085FD;
    width: 10px;
    height: 10px;
    border: 1px;
    border-color: #707070;
    border-radius: 50%;
    margin-right: 4px;
}

.redBall {
    background-color: #E22C29;
    width: 10px;
    height: 10px;
    border: 1px;
    border-color: #707070;
    border-radius: 50%;
    margin-right: 4px;
}

.greyBall {
    background-color: #979797;
    width: 10px;
    height: 10px;
    border: 1px;
    border-color: #707070;
    border-radius: 50%;
    margin-right: 4px;
}

.legendItem {
    display: inline-flex;
    flex-direction: row;
    align-items: baseline;
}

.legendItem:last-of-type {
    margin-right: 0px;
  }

@media screen and (max-width: 1300px) {
    .selectWrapper {
        width: 190px;
    }
}

.fieldListTopSection {
    overflow: scroll;
}

@media screen and (max-width: 800px) {
    .fieldListTopBarWrapper {
        flex-direction: column;
        height: 100%;
    }

    .leftSide {
        flex-direction: row;
    }

    /* .searchBarWrapper {
        width: 30%;
    } */
}

@media screen and (max-width: 680px) {
    .leftSide {
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }

    .fieldListTopBarWrapper {
        flex-direction: row;
        align-items: flex-start;
    }

    .fieldListTopBar {
        margin-left: 0px;
        margin-right: 0px;
    }

    .selectWrapper {
        width: 100%;
        padding-right: 0px;
    }

    .legendRight {
        gap: 10px;
        margin-left: 10px;
    }

    .fieldListTopBarLegend {
        flex-wrap: wrap;
    }
    .legendRight {
        margin-left: 0px;
    }
}

@media screen and (max-width: 380px) {
    .fieldListTopBarWrapper {
        row-gap: 10px;
        flex-direction: column;
    }
}

.rightSide {
    margin-bottom: 5px;
}