.headder {
    width: 100%;
    margin-left: 30px;
    margin-right: 10px;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.CardItems {
    /* change this to show how many columns you want to show in one row */
    --items-to-show-in-one-row: 5;
    display: grid;

    /* Idk how i got this to work, please don't touch this, it works */
    grid-template-columns: repeat(var(--items-to-show-in-one-row), calc((100% - 110px)/var(--items-to-show-in-one-row)) [col-start]);

    width: 100%;
    row-gap: 40px;
    column-gap: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.ItemAction {
    display: flex;
    height: 40px;
    align-items: baseline;
    font-size: 0.9rem;
    font-weight: 300;
    margin-top: 10px;
}

/* use media queries to adjust --items-to-show-in-one-row for smaller viewport widths */
@media (max-width: 1630px) {
    .CardItems {
        --items-to-show-in-one-row: 4;
    }
}

@media (max-width: 1260px) {
    .CardItems {
        --items-to-show-in-one-row: 3;
    }
}

@media (max-width: 1024px) {
    .CardItems {
        --items-to-show-in-one-row: 2;
    }
}

@media (max-width: 770px) {
    .CardItems {
        --items-to-show-in-one-row: 2;
    }
}