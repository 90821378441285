.tile {
    width: 9%;
    height: 24px;
    border-radius: 4px;
    opacity: 1;
    background-origin: padding-box;
    background-repeat: no-repeat;
}

.wrapper {
    display: flex;
    justify-content: space-between;

    padding-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
}

.fieldDetailLink {
    text-decoration: none;
}

.titleWrapper {
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 0px;
}

.fieldDetailLinkWrapper {
    display: flex;
    flex-direction: row;
    font-family: 'poppins';
    color: #000000;
    text-decoration: none;
    white-space: nowrap;
    /* padding: 10px; */
}

.fieldDetailLinkWrapper .farmName {
    /* display: none; */
    opacity: 0;
    width: 0px;
}

.fieldDetailLinkWrapper:hover .farmName {
    /* display: block; */
    color: #0958FF;
    width: auto;
    opacity: 1;
    transition: all 0.3s ease-out;
}

.fieldDetailLinkWrapper:hover .fieldDetailLink {
    /* display: block; */
    color: #0958FF;
}

.fieldDetailLinkWrapper:hover .rightIcon {
    /* display: block; */
    color: #0958FF;
}

.farmName {
    font-family: 'poppins';
    width: 100px;
}
.irrigationForcastItemwrapper {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 800px) {

    .irrigationForcastItemwrapper {
        width: 860px;
    }
}

@media only screen and (max-width: 680px) {

    .wrapper,
    .titleWrapper {
        padding-left: 10px;
    }
}

.Crop {
    display: flex;
    flex-direction: row;
    margin-right: 0.5rem;
    border: 1.5px solid #2D9C41;
    height: 1.2rem;
    width: auto;
}
