.CumulativeDayDegreesHeader {
    margin-left: 30px;
    margin-right: 10px;
    font-weight: 600;
    font-size: 1rem;
}

.fieldDetailBody {
    margin-left: 0px;
    margin-right: 0px;
}

.headerRight {
    margin-right: 30px;
}

.shockDays {
    margin-left: 10px;
    font-weight: 300;
    font-size: 0.9rem;
}