.sideImage {
    width: 450px;
}

@media screen and (max-width: 500px)  {
    .sideImage {
        width: 250px;
    }
}

@media screen and (max-height: 500px) {
    .sideImage {
        width: 250px;
    }
}