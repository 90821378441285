.headder {
  margin-left: 30px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Body {
  display: flex;
  flex-direction: column;
  border: 10px;
  justify-content: space-between;
  font-weight: 100;
}

.Editor {
  height: 300px;
  position: relative;
  width: 100%;
}

.saveButton {
  position: absolute;
  margin-right: 48px;
  margin-top: auto;
  margin-bottom: -48px;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 900px) {
  .saveButton {
    margin-bottom: -48px;
  }
}