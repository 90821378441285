.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../assets/images/Background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    left: 0px;
    font-family: 'Montserrat';
    font-size: large;
  }
  
  .card {
    position: fixed;
    top: 50%;
    left: 70%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: visible;
    width: 400px;
    margin-right: 36px;
    font-family: 'Montserrat';
  }
  
  .cardHeader {
    text-align: center;
    padding-bottom: 0px;
  }
  
  .loginForm {
    margin: 30px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    font-family: 'Montserrat';
  }
  
  .cardContent {
    margin: 10px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-items: stretch;
  }
  
  .loginTextField {
    flex: 1 1 auto;
    text-align: center;
    margin-left: 5px;
    /* and that, will result in a 10px gap */
    margin-right: 5px;
    margin-bottom: 15px;
  }
  
  .loginText {
    position: fixed;
    top: 30%;
    left: 10%;
  }
  
  /* Media queries for different screen sizes */
  @media only screen and (max-width: 580px) {
    .wrapper {
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
    /* Portrait mode for mobile phones */
    .card {
      top: auto;
      left: auto;
      transform: none;
      position: relative;
      overflow: visible;
      width: 90vw;
      align-self: center;
      margin-right: 0px;
    }
  
    .loginText {
      position: relative;
      align-self: center;
      top: auto;
      left: auto;
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-height: 360px) {
    .card {
        height: 90vh;
    }
  }

  @media only screen and (max-width: 767px) and (min-width: 580px) {
    .card {
        width: 350px;
    }
}

  @media only screen and (max-height: 644px) and (min-width: 580px) and (orientation: landscape) {
    .loginText {
      top: 10%;
    }
  }