.pageHeader {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: stretch;
}

.Title {
    font: 600 24px 'Poppins';
    letter-spacing: 0.11px;
    text-align: center;

}

.IrrigationTitle {
    font: 400 14px 'Poppins';
    letter-spacing: 0.11px;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 23.61px;
    margin-top: 24px;
}

.topLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width:500px) {
    .IrrigationTitle {
        /* display: block; */
        flex-direction: column;
        justify-content: start;
        margin-top: 0px;
        gap: 3px
    }
}