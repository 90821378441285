#ShortcutsCard {
    max-width: 500px;
    width: 500px;
    height: 350px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 16px;
}

#ShortcutsCard:hover {
    box-shadow: 0px 3px 18px #00000029;
}

.Title {
    font: 600 18px/27px Poppins;
    padding-top: 14px;
}

.List {
    flex: 1 1 auto;
    width: 100%;
    position: relative;
    overflow-y: auto;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    max-height: 255px;
}

.List::-webkit-scrollbar {
    width: 4px;
}

.List::-webkit-scrollbar-track {
    background: #FFF;
}

.List::-webkit-scrollbar-thumb {
    background: #CFD0D1;
    border-radius: 5px;
    height: 80px;
}

.List::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.LinkText {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
}

.LinkText a {
    font: 500 16px/24px Poppins;
    color: #000;
    text-decoration: none;
    min-height: 85px;
    max-height: 85px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin-left: 5px;
    width: 100%;
    margin-right: 20px;
}

@media screen and (max-width: 1200px) {
    #ShortcutsCard {
        width: calc(48vw - 80px);
    }
}

@media screen and (max-width: 800px) and (min-height:500px) {
    #ShortcutsCard {
        width: 500px;
    }
}

@media screen and (max-width: 620px) {
    #ShortcutsCard {
        width: calc(90vw - 80px);
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 500px) {
    #ShortcutsCard {
        width: 88vw;
    }
}

@media screen and (max-height: 500px) and (orientation:landscape) {
    #ShortcutsCard {
        width: 100%;
    }
}