.drawerTitle {
    display: flex;
    justify-content: flex-end;
    margin-top: 17px;
    padding: 0 !important;
}

@media screen and (max-width: 500px) {
    .drawerTitle {
        position: fixed;
        width: 100%;
        width: 100px;
        right: 3px;
        z-index: 1201;
        margin-top: 4px;
    }

    .drawerContent {
        margin-top: 54px;
    }
}