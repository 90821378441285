.wrapper {
    padding: 24px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.grid {
    width: 100%;
    height: calc(100vh - 300px);
}

.pageHeader {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    align-items: stretch;
}

.Title {
    font: 500 18px 'Poppins';
    letter-spacing: 0.11px;
    text-align: center;
}

.toggleButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.topLine {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.link, .link:hover, .link:visited, .link:focus, .link:active  {
    text-decoration: none;
    color: inherit;
    outline: 0;
    cursor: auto;
  }

  .subrouteWrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
@media screen and (max-width: 500px) {
    .fieldList {
      padding-left: 0px;
      padding-right: 0px;
    }

    .subrouteWrapper {
        padding-left: 20px;
        padding-right: 20px;
    }
}
